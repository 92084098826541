import React from 'react'
import arrowLeft from '../../../img/arrowLeft.png'
import arrowRight from '../../../img/arrowRight.png'
import { UserVideoComponent } from '../UserVideoComponent/UserVideoComponent'
import styles from './SubscribersList.module.css'

export const SubscribersList = ({
  subscribers,
  SHARE_SCREEN_NAME,
  handleMainVideoStream,
  handleFoolScreenVideoStream,
  toggleSubscribeAudio,
}) => {
  return (
    <div className={styles.container}>
      {subscribers.length !== 0 && <img src={arrowRight} style={{ width: '80px', transform: "rotate(180deg)", height: '120px', marginRight: '14px'}} />}
      <div className={styles.subscribers}>
        {subscribers
          .filter(e => JSON.parse(e.stream.connection.data).clientName !== SHARE_SCREEN_NAME)
          .map((sub, i) => (
            <div key={i} className={styles.streamContainer} onClick={() => handleMainVideoStream(sub)}>
              <UserVideoComponent
                streamManager={sub}
                handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                toggleSubscribeAudio={toggleSubscribeAudio}
              />
            </div>
          ))}
      </div>
      {subscribers.length !== 0 && <img src={arrowRight} style={{ width: '80px', marginLeft: '15px', height: '120px' }} />}
    </div>
  )
}
