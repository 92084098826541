import React, { useEffect, useState } from 'react'
import { Video } from '../Video/Video'
import styles from './User.module.css'

export const User = ({ streamManager, handleFoolScreenVideoStream, openUsersList, toMainStream, toggleSubscribeAudio }) => {
  // const [render, setRender] = useState()

  // useEffect(() => {
  //   setInterval(() => setRender(render + 1), 3000)
  //   return () => clearInterval(intervalId)
  // }, [])
  // console.log(render)

  if (streamManager === undefined) return null
  return (
    <Video
      streamManager={streamManager}
      handleFoolScreenVideoStream={handleFoolScreenVideoStream}
      openUsersList={openUsersList}
      toMainStream={toMainStream}
      toggleSubscribeAudio={toggleSubscribeAudio}
    />
  )
}
