import Tippy from '@tippy.js/react'

export const Tooltip = ({ children, ...rest }) => <Tippy {...rest}>{children}</Tippy>

Tooltip.defaultProps = {
  delay: 0,
  duration: 0,
  arrow: true,
  theme: 'translucent',
}
