import React, {useEffect} from "react";
import { UserVideoComponent } from "./UserVideoComponent/UserVideoComponent";
import { SubscribersList } from "./SubscribersList/SubscribersList";
import { OpenViduVideoComponent } from "./OvVideo/OvVideo";
import styles from "./View1.module.css";

export const View1 = ({
  state,
  screenWidth,
  handleChangeUsername,
  handleChangeOrganization,
  handleChangePosition,
  shareScreenSubscriber,
  SHARE_SCREEN_NAME,
  handleMainVideoStream,
  handleFoolScreenVideoStream,
  handleFoolScreenVideoStreamNew,
  toggleSubscribeAudio = { toggleSubscribeAudio },
}) => {
  
  console.log(state)

  const getTopic = () => {
    const subsTopic = state.subscribers
                        .map(it => JSON.parse(it.stream.connection.data).topic)
                        .find(it => it)
    const publTopic = JSON.parse(state.publisher.stream.connection.data).topic
    return subsTopic || publTopic
  }

  const {subscribers, speakingUserId} = state

  return (
    <>
      <div className={styles.topicTitle}>{getTopic()}</div>
      <div className={styles.wrapper}>
        

        <UserVideoComponent
          style={shareScreenSubscriber() ? {
            transform: `rotateY(20deg)`,
            marginTop: "30px",
            width: "350px"
          } : {
            transform: `rotateY(10deg) scale(${(screenWidth > 1000 ? 0.001*screenWidth : 1)})`,
            marginTop: "80px",
            width: "360px"
          }}
          className={styles.userVideo}
          streamManager={state.publisher}
          isPublisher={true}
          isSubscribers={state.mainStreamManager}
          changeUsername={handleChangeUsername}
          changeOrganization={handleChangeOrganization}
          changePosition={handleChangePosition}
          myUsername={state.myUsername}
          myOrganization={state.myOrganization}
          myPosition={state.myPosition}
          main
          toggleSubscribeAudio={toggleSubscribeAudio}
        />

        {!!shareScreenSubscriber() && <OpenViduVideoComponent
          main
          streamManager={shareScreenSubscriber()}
          screen
          toggleSubscribeAudio={toggleSubscribeAudio}
          handleFoolScreenVideoStream={handleFoolScreenVideoStream}
          handleFoolScreenVideoStreamNew={handleFoolScreenVideoStreamNew}
        />}

        <UserVideoComponent
          main
          style={shareScreenSubscriber() ? {
            transform: `rotateY(-20deg)`,
            marginTop: "30px",
            width: "350px"
          } : {
            transform: `rotateY(-10deg) scale(${(screenWidth > 1000 ? 0.001*screenWidth : 1)})`,
            marginTop: "80px",
            width: "360px"
          }}
          className={styles.userVideo}
          streamManager={/* state.mainStreamManager */ subscribers.find(it => it.stream.connection.connectionId === speakingUserId)}
          toggleSubscribeAudio={toggleSubscribeAudio}
        />
      </div>

      <SubscribersList
        SHARE_SCREEN_NAME={SHARE_SCREEN_NAME}
        subscribers={state.subscribers}
        handleMainVideoStream={handleMainVideoStream}
        handleFoolScreenVideoStream={handleFoolScreenVideoStream}
        toggleSubscribeAudio={toggleSubscribeAudio}
      />
    </>
  );
};
