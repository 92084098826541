import React from 'react'
import styles from './Layout3.module.css'

export const Layout3 = ({ changeView }) => {
  return (
    <div className={styles.wrapper} onClick={() => changeView(3)}>
      <div className={styles.topRow}>
        <div className={styles.mainUser}></div>
        <div>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.user}></div>
        <div className={styles.user}></div>
        <div className={styles.user}></div>
        <div className={styles.user}></div>
      </div>
    </div>
  )
}
