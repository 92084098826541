import React from 'react'
import { Video } from '../Video/Video'
import styles from './User.module.css'

export const User = ({ streamManager, handleFoolScreenVideoStream, openUsersList, toMainStream, toggleSubscribeAudio }) => {
  if (streamManager === undefined) return null

  return (
    <Video
      streamManager={streamManager}
      handleFoolScreenVideoStream={handleFoolScreenVideoStream}
      openUsersList={openUsersList}
      toMainStream={toMainStream}
      toggleSubscribeAudio={toggleSubscribeAudio}
    />
  )
}
