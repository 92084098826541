import React, { useState } from 'react'
import styles from './ChooseLayout.module.css'
import { Layout1 } from './Layout1/Layout1'
import { Layout2 } from './Layout2/Layout2'
import { Layout3 } from './Layout3/Layout3'
import { Layout4 } from './Layout4/Layout4'
import { Layout5 } from './Layout5/Layout5'

export const ChooseLayout = ({ changeView }) => {
  const [layoutsVisible, setLayoutsVisible] = useState(false)

  return (
    <div className={styles.wrapper} onClick={() => setLayoutsVisible(prev => !prev)}>
      <div className={styles.squares}></div>
      <div className={styles.squares}></div>
      <div className={styles.squares}></div>
      <div className={styles.squares}></div>
      {layoutsVisible && (
        <div className={styles.select}>
          <Layout1 changeView={changeView} />
          <Layout2 changeView={changeView} />
          <Layout3 changeView={changeView} />
          <Layout4 changeView={changeView} />
          {/* <Layout5 changeView={changeView} /> */}
        </div>
      )}
    </div>
  )
}
