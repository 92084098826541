import React from 'react'
import styles from './Layout1.module.css'

export const Layout1 = ({ changeView }) => {
  return (
    <div className={styles.wrapper} onClick={() => changeView(1)}>
      <div className={styles.topRow}>
        <div className={styles.mainUser}></div>
        <div className={styles.screen}></div>
        <div className={styles.mainUser}></div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
      </div>
    </div>
  )
}
