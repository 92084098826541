import React, { Component } from 'react'
import styles from './Video.module.css'
import fullScreenIcon from '../../../img/icons/fullScreen.png'
import listIcon from '../../../img/icons/list.png'
import homeIcon from '../../../img/icons/home.png'
import { Tooltip } from '../../Tooltip/Tooltip'
import { SHARE_SCREEN_NAME } from '../../VideoChatPageClass'
import voiceActive from '../../../img/icons/voice-active.png'
import voiceNotActive from '../../../img/icons/voiceNotActive.png'

export class Video extends Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  getNameTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data).clientName
  }

  getOrganizationTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data).clientOrganization
  }

  getPositionTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data).clientPosition
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <video autoPlay={true} ref={this.videoRef} />

        {!this.props.subscribers ? (
          <div className={styles.descriptionWrapper}>
            {SHARE_SCREEN_NAME !== this.getNameTag() && (
              <div className={styles.descriptionMain}>
                <p> {this.getNameTag()}</p>
                <p> {`${this.getOrganizationTag()}, ${this.getPositionTag()}`}</p>
              </div>
            )}
            <Tooltip content='на весь экран'>
              <img
                className={this.props.toMainStream ? styles.fillScreen : styles.fillScreenMain}
                src={fullScreenIcon}
                onClick={() => this.props.handleFoolScreenVideoStream(this.props.streamManager)}
              />
            </Tooltip>
            {SHARE_SCREEN_NAME !== this.getNameTag() && this.props.toggleSubscribeAudio && (
              <Tooltip content='микрофон'>
                <img
                  className={styles.microphone}
                  src={this.props.streamManager.stream.audioActive ? voiceActive : voiceNotActive}
                  // onClick={() => this.props.toggleSubscribeAudio(this.props.streamManager)}
                />
              </Tooltip>
            )}
            {SHARE_SCREEN_NAME !== this.getNameTag() && this.props.toggleSubscribeAudio && (
              <Tooltip content='заменить участника'>
                <img
                  className={this.props.toMainStream ? styles.list : styles.listMain}
                  src={listIcon}
                  onClick={this.props.openUsersList}
                />
              </Tooltip>
            )}
          </div>
        ) : (
          <div className={styles.description}>
            <p> {this.getNameTag()}</p>
            <p> {`${this.getOrganizationTag()}, ${this.getPositionTag()}`}</p>
          </div>
        )}
      </div>
    )
  }
}
