import React, { Component } from "react";
import styles from "./Video.module.css";
import fullScreenIcon from "../../../img/icons/fullScreen.png";
import listIcon from "../../../img/icons/list.png";
import homeIcon from "../../../img/icons/home.png";
import { Tooltip } from "../../Tooltip/Tooltip";
import { SHARE_SCREEN_NAME } from "../../VideoChatPageClass";
import voiceActive from "../../../img/icons/voice-active.png";
import voiceNotActive from "../../../img/icons/voiceNotActive.png";

export class Video extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  getNameTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data)
      .clientName;
  };

  getOrganizationTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data)
      .clientOrganization;
  };

  getPositionTag = () => {
    return JSON.parse(this.props.streamManager.stream.connection.data)
      .clientPosition;
  };

  render() {

    return (
      <div className={styles.wrapper}>
        <video autoPlay={true} ref={this.videoRef} />

        {/* {this.props.toMainStream ? ( */}
          <div
            className={
              this.props.toMainStream
                ? styles.descriptionWrapper
                : styles.descriptionWrapper + " " + styles.main
            }
          >
            {SHARE_SCREEN_NAME !== this.getNameTag() && (
              <div
                className={
                  this.props.toMainStream
                    ? styles.description
                    : styles.descriptionMain
                }
              >
                <p> {this.getNameTag()}</p>
                {this.props.toMainStream ? (
                  <>
                    <p> {this.getOrganizationTag()}</p>
                    <p> {this.getPositionTag()}</p>
                  </>
                ) : (
                  <p>
                    {" "}
                    {`${this.getOrganizationTag()}, ${this.getPositionTag()}`}
                  </p>
                )}
              </div>
            )}
            <Tooltip content="на весь экран">
              <img
                className={
                  this.props.toMainStream
                    ? styles.fillScreen
                    : styles.fillScreenMain
                }
                alt="o_O"
                src={fullScreenIcon}
                onClick={() =>
                  this.props.handleFoolScreenVideoStream(
                    this.props.streamManager
                  )
                }
              />
            </Tooltip>
            {SHARE_SCREEN_NAME !== this.getNameTag() &&
              this.props.toggleSubscribeAudio && (
                <Tooltip content="микрофон">
                  <img
                    className={styles.microphone}
                    alt="o_O"
                    src={
                      this.props.streamManager.stream.audioActive
                        ? voiceActive
                        : voiceNotActive
                    }
                     onClick={() => this.props.toggleSubscribeAudio(this.props.streamManager)}
                  />
                </Tooltip>
              )}
            {SHARE_SCREEN_NAME !== this.getNameTag() &&
              this.props.openUsersList &&
              this.props.toggleSubscribeAudio && (
                <Tooltip content="заменить участника">
                  <img
                    className={
                      this.props.toMainStream ? styles.list : styles.listMain
                    }
                    alt="o_O"
                    src={listIcon}
                    onClick={this.props.openUsersList}
                  />
                </Tooltip>
              )}

            {this.props.toMainStream && (
              <Tooltip content="сделать главным">
                <img
                  className={styles.home}
                  alt="o_O"
                  src={homeIcon}
                  onClick={this.props.toMainStream}
                />
              </Tooltip>
            )}
          </div>
        {/* ) : (
          <div>
            <span>{this.getNameTag()}</span>
            <Tooltip content="на весь экран">
              <img
                className={
                  this.props.toMainStream
                    ? styles.fillScreen
                    : styles.fillScreenMain
                }
                alt="o_O"
                src={fullScreenIcon}
                onClick={() => this.props.handleFoolScreenVideoStream(this.props.streamManager)}
              />
          </Tooltip>
          </div>
        )} */}
      </div>
    );
  }
}
