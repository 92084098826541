import React, { Component } from 'react'
import styles from './FoolScreen.module.css'
import fullScreenIcon from '../../img/icons/fullScreen.png'

export class FoolScreen extends Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  render() {
    return (
      <div className={styles.foolScreen}>
        <video autoPlay={true} ref={this.videoRef} />
        <img src={fullScreenIcon} alt="o_O" onClick={() => this.props.handleFoolScreenVideoStream(this.props.streamManager)} />
      </div>
    )
  }
}
