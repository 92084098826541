import React, {useState} from 'react'
import styles from './UsersCount.module.css'

export const UsersCount = ({ usersCount, list }) => {
  const [click, setClick] = useState(false);
  return (
    <>
      {
        click && list.value.length > 0 &&
        <div className={styles.listWrapper}>
        {list.value
        .filter(el => JSON.parse(el.metadata).clientName !== '_screen-share_')
        .map(el => 
        <div 
        className={styles.listItem}>
          <span>{JSON.parse(el.metadata).clientName}</span> 
          <span> {JSON.parse(el?.metadata).clientOrganization}</span>
          <span> {JSON.parse(el.metadata).clientPosition}</span>
           </div>
        )
        }
      </div>
      }

      <div className={styles.wrapper} onClick={() => setClick(prev => !prev)}>
        {
        // list.value
        // .filter(el => JSON.parse(el.metadata).clientName == '_screen-share_').length  
        // ?
        // list.value.length
        // :
        // list.value.length + 1 
        usersCount
         } {usersCount === 1 ? 'участник' : usersCount > 4 ? 'участников' : 'участника'}
      </div>
    </>
  )
}
