import React, { Component } from "react";
import styles from "./OvVideo.module.css";
import fullScreenIcon from "../../../img/icons/fullScreen.png";
import voiceActive from "../../../img/icons/voice-active.png";
import voiceNotActive from "../../../img/icons/voiceNotActive.png";
import { Tooltip } from "../../Tooltip/Tooltip";

export class OpenViduVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.style)
    if (this.props && !!this.videoRef && this.props.streamManager) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef && this.props.streamManager) {
      this.props.streamManager.addVideoElement(this.videoRef.current);
    }
    console.log(this.props.streamManager)
  }

  render() {
    if (this.props.screen) {
      return (
        <div className={styles.OvVideoContainerSahreScreen}>
          <video autoPlay={true} ref={this.videoRef} />
          <Tooltip content="на весь экран">
            <img
              src={fullScreenIcon}
              alt="o_O"
              onClick={() =>
                this.props.handleFoolScreenVideoStream(this.props.streamManager)
              }
            />
          </Tooltip>
          <Tooltip content="на весь экран 2">
            <img
              src={fullScreenIcon}
              alt="o_O"
              onClick={() =>
                this.props.handleFoolScreenVideoStreamNew(this.props.streamManager)
              }
            />
          </Tooltip>
        </div>
      );
    }

    if (this.props.main) {
      return this.props.isPublisher ? (
        <div className={styles.OvVideoContainerPublisherMain}>
          <video autoPlay={true} ref={this.videoRef} />
        </div>
      ) : (
        <div className={styles.OvVideoContainerMain}>
          <video autoPlay={true} ref={this.videoRef} />
        </div>
      );
    }

    return this.props.isPublisher && this.props.isSubscribers ? (
      <div className={styles.OvVideoContainerPublisher}>
        <video autoPlay={true} ref={this.videoRef} />
      </div>
    ) : this.props.isPublisher ? (
      <div className={styles.OvVideoContainerPublisherOnly}>
        <video autoPlay={true} ref={this.videoRef} />
      </div>
    ) : (
      <div className={styles.OvVideoContainer}>
        <video autoPlay={true} ref={this.videoRef} />
        <Tooltip content="микрофон">
          <img
            className={styles.microphone}
            src={
              this.props.streamManager.stream.audioActive
                ? voiceActive
                : voiceNotActive
            }
            alt="o_O"
            // onClick={() => this.props.toggleSubscribeAudio(this.props.streamManager)}
          />
        </Tooltip>
        <Tooltip content="на весь экран">
          <img
            src={fullScreenIcon}
            alt="o_O"
            onClick={() =>
              this.props.handleFoolScreenVideoStream(this.props.streamManager)
            }
          />
        </Tooltip>
      </div>
    );
  }
}
