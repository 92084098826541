import { format } from "date-fns/esm";
import { useEffect, useState } from "react";
import { ChooseLayout } from "./VideoChatPage/ChooseLayout/ChooseLayout";
import styles from "./App.module.css";
import emblem from "./img/emblem.png";
import label from "./img/label.png";
import LMS from "./img/LMS.png";
import logo from "./img/logo.png";
import logo_MO from "./img/logo_MO.png";
import VideoChatPage from "./VideoChatPage/VideoChatPageClass";
import { useWindowSize } from "./assets/useWindowSize";
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css';
import { version } from "./assets/const";



const MONTHS = [
  "",
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

const getDate = () => {
  const year = format(new Date(), "yyyy");
  const month = MONTHS[+format(new Date(), "MM")];
  const day = format(new Date(), "dd");

  return `КОНФЕРЕНЦИЯ ${day} ${month} ${year} Г.`;
};

export const App = () => {
  
  const TARGET = process.env.REACT_APP_TARGET

  const [foolScreenNew, setFoolScreenNew] = useState(undefined)
  const { width } = useWindowSize();
  const [view, setView] = useState(1);
  const [screen, setScreen] = useState(1);
  const [session, setSession] = useState(false);

  useEffect(() => {
    width < 560 ? setScreen(5) : setScreen(view);
  }, [width]);

  useEffect(() => {
    setScreen(view)
  },[view])

  return (
    <>
    <div className={styles.container}>
      <NotificationContainer/>
      <header style={foolScreenNew && {position : "absolute", top: "0", left : "20%"}}>
        {width<895 &&
          <div>
            <div className={styles.conferenceHead} >
              <h2>Система дистанционного обучения &#171;Курсант&#187;</h2>
            <h1>{getDate()}</h1>
            </div>
          </div>
        }
        <div className={styles.header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={TARGET==="kursant" ? emblem : label} alt="o_o" className={styles.logo} />
          {session && width > 560 && (
            <ChooseLayout changeView={setView} />
          )}
        </div>
        {width>895 &&
          <div>
            <div className={styles.conferenceHead} style={foolScreenNew && {zIndex : "1000"}} >
              <h2>Система дистанционного обучения &#171;Курсант&#187;</h2>
            <h1>{getDate()}</h1>
            </div>
          </div>
        }
        <div className={styles.images}>
          <img src={logo} alt="o_o"/>
          {TARGET==='kursant' &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <img src={logo_MO} className={styles.logos} alt="o_o"/>
              <img src={LMS} className={styles.logos} alt="o_o"/>
            </div>
          }
        </div>
        </div>
      </header>
      <VideoChatPage
        foolScreenNew={foolScreenNew}
        setFoolScreenNew={setFoolScreenNew}
        changeView={setView}
        view={screen}
        setAppSession={setSession}
        screenWidth={width}
      />
    </div>
    <div className={styles.version}>{version}</div>
    </>
  );
};
