import React, { useState } from 'react'
import { OpenViduVideoComponent } from '../OvVideo/OvVideo'
import pencilIcon from '../../../img/icons/pencil.png'
import styles from './UserVideoComponent.module.css'

export const UserVideoComponent = ({style, ...props}) => {
  const [usernameEdit, setUserameEdit] = useState(false)
  const [organizationEdit, setOrganizationEdit] = useState(false)
  const [positionEdit, setPositionEdit] = useState(false)

  const getNameTag = () => {
    if (props.streamManager) return JSON.parse(props.streamManager.stream.connection.data).clientName
  }

  const getOrganizationTag = () => {
    if (props.streamManager) return JSON.parse(props.streamManager.stream.connection.data).clientOrganization
  }

  const getPositionTag = () => {
    if (props.streamManager) return JSON.parse(props.streamManager.stream.connection.data).clientPosition
  }
  // if (props.streamManager === undefined) return null
  return props.main ? (
    <div className={styles.streamcomponentMain} style={style}>
      <OpenViduVideoComponent
        streamManager={props.streamManager}
        isPublisher={props.isPublisher}
        isSubscribers={props.isSubscribers}
        main={props.main}
        toggleSubscribeAudio={props.toggleSubscribeAudio}
      />
      <div>
        {usernameEdit ? (
          <div className={styles.tagChangeWrapperMain}>
            <input
              defaultValue={props.myUsername}
              onChange={props.changeUsername}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setUserameEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTagMain}>
            <h2>{props.myUsername ? props.myUsername : getNameTag() || <span style={{ opacity: '0' }}>asdafasfd</span>}</h2>
            {props.isPublisher && <img onClick={() => setUserameEdit(true)} alt="o_O" src={pencilIcon} className={styles.edit} />}
          </div>
        )}
        {organizationEdit ? (
          <div className={styles.tagChangeWrapperMain}>
            <input
              defaultValue={props.myOrganization}
              onChange={props.changeOrganization}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setOrganizationEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTagMain}>
            <h2>
              {props.myOrganization
                ? props.myOrganization
                : getOrganizationTag() || <span style={{ opacity: '0' }}>asdafasfd</span>}
            </h2>
            {props.isPublisher && <img onClick={() => setOrganizationEdit(true)} src={pencilIcon} className={styles.edit} />}
          </div>
        )}
        {positionEdit ? (
          <div className={styles.tagChangeWrapperMain}>
            <input
              defaultValue={props.myPosition}
              onChange={props.changePosition}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setPositionEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTagMain}>
            <h2>{props.myPosition ? props.myPosition : getPositionTag() || <span style={{ opacity: '0' }}>asdafasfd</span>}</h2>
            {props.isPublisher && <img onClick={() => setPositionEdit(true)} src={pencilIcon} className={styles.edit} />}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.streamcomponent}>
      <OpenViduVideoComponent
        streamManager={props.streamManager}
        isPublisher={props.isPublisher}
        isSubscribers={props.isSubscribers}
        handleFoolScreenVideoStream={props.handleFoolScreenVideoStream}
      />
      <div>
        {usernameEdit ? (
          <div className={styles.tagChangeWrapper}>
            <input
              defaultValue={props.myUsername}
              onChange={props.changeUsername}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setUserameEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTag}>
            <h2>{props.myUsername ? props.myUsername : getNameTag()}</h2>
            {props.isPublisher && <img onClick={() => setUserameEdit(true)} src={pencilIcon} className={styles.edit} />}
          </div>
        )}
        {organizationEdit ? (
          <div className={styles.tagChangeWrapper}>
            <input
              defaultValue={props.myOrganization}
              onChange={props.changeOrganization}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setOrganizationEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTag}>
            <h2>{props.myOrganization ? props.myOrganization : getOrganizationTag()}</h2>
            {props.isPublisher && <img onClick={() => setOrganizationEdit(true)} src={pencilIcon} className={styles.edit} />}
          </div>
        )}
        {positionEdit ? (
          <div className={styles.tagChangeWrapper}>
            <input
              defaultValue={props.myPosition}
              onChange={props.changePosition}
              autoFocus
              onKeyDown={e => (e.key === 'Enter' ? setPositionEdit(false) : null)}
            />
          </div>
        ) : (
          <div className={styles.myTag}>
            <h2>{props.myPosition ? props.myPosition : getPositionTag()}</h2>
            {props.isPublisher && <img onClick={() => setPositionEdit(true)} src={pencilIcon} className={styles.edit} />}
          </div>
        )}
      </div>
    </div>
  )
}
