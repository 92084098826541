import React, { useCallback, useEffect, useState } from 'react'
import { Popover } from '../Popover/Popover'
import { SubscribersList } from './SubscribersList/SubscribersList'
import { SwapUsersSelect } from './SwapUsersSelect/SwapUsersSelect'
import { User } from './User/User'
import styles from './View4.module.css'

export const View4 = ({ subscribers = [], publisher, handleFoolScreenVideoStream, toggleSubscribeAudio }) => {
  const [visibleUsers, setVisibleUsers] = useState([publisher, ...subscribers.slice(0, 7)])
  const [visiblePopover, setVisiblePopover] = useState(false)

  const visiblePopoverFasle = useCallback(e => {
    if (e.key === 'Escape') {
      setVisiblePopover(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', visiblePopoverFasle)
    return () => {
      document.removeEventListener('keydown', visiblePopoverFasle)
    }
  }, [])

  useEffect(() => {
    setVisibleUsers([...visibleUsers, ...subscribers.slice(visibleUsers.length - 1, 7)])
  }, [subscribers.length])

  const handleSwapUsers = user => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[visiblePopover] = user
    setVisibleUsers(newVisibleUsers)
    setVisiblePopover(false)
  }

  const usersForSelect = [publisher, ...subscribers].filter(user => !visibleUsers.some(e => e === user))

  const handleToMainStream = index => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[0] = visibleUsers[index]
    newVisibleUsers[index] = visibleUsers[0]
    setVisibleUsers(newVisibleUsers)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.mains}>
        <Popover visible={visiblePopover === 0} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[0] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(0)}
                  streamManager={visibleUsers[0]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 1} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[1] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(1)}
                  streamManager={visibleUsers[1]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 2} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[2] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(2)}
                  streamManager={visibleUsers[2]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 3} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[3] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(3)}
                  streamManager={visibleUsers[3]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 4} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[4] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(4)}
                  streamManager={visibleUsers[4]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 5} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[5] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(5)}
                  streamManager={visibleUsers[5]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 6} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[6] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(6)}
                  streamManager={visibleUsers[6]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover visible={visiblePopover === 7} content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}>
          <div className={styles.user}>
            {visibleUsers[7] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(7)}
                  streamManager={visibleUsers[7]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
      </div>
      <SubscribersList users={usersForSelect} />
    </div>
  )
}
