import axios from "axios";
import { OpenVidu } from "openvidu-browser";
import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import { Controllers } from "./Controllers/Controllers";
import { Form } from "./Form/Form";
import "./VideoChatPage.css";
import { FoolScreen } from "./FoolScreen/FoolScreen";
import { NewFoolScreen } from "./NewFoolScreen/NewFoolScreen";
import { View1 } from "./View1/View1";
import { View2 } from "./View2/View2";
import { View3 } from "./View3/View3";
import { View5 } from "./View5/View5";
import { View4 } from "./View4/View4";
import { Chat } from "./Chat/Chat";
import { UsersCount } from "./UsersCount/UsersCount";
import { createNotification } from "./Notifications/Notifications";

export const SHARE_SCREEN_NAME = "_screen-share_";

class VideoChatPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mySessionId: this.props.location.pathname.substr(1)
        ? this.props.location.pathname.substr(1)
        : "SessionA-" + Date.now() ,
      mySessionPath: this.props.location.pathname.substr(1)
      ? window.location.href : window.location.href + "SessionA-" + Date.now(),
      myUsername: "",
      myOrganization: "",
      myPosition: "",
      topic: "",
      session: undefined,
      session2: undefined,
      mainStreamManager: undefined,
      foolScreenStreamManager: undefined,
      // foolScreenStreamManagerNew: undefined,
      publisher: undefined,
      publisher2: undefined,
      subscribers: [],
      render: null,
      microphoneId: undefined,
      cameraId: undefined,
      visibleChat: false,
      messages: [],
      isLeader: /* window.location.search || '' */ true,
      speakingUserId: null
    };
    
    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.handleChangeSessionId = this.handleChangeSessionId.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangeTopic = this.handleChangeTopic.bind(this);
    this.handleChangeOrganization = this.handleChangeOrganization.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
    this.handleFoolScreenVideoStream = this.handleFoolScreenVideoStream.bind(this);
    this.handleFoolScreenVideoStreamNew = this.handleFoolScreenVideoStreamNew.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.togglePublishAudio = this.togglePublishAudio.bind(this);
    this.togglePublishVideo = this.togglePublishVideo.bind(this);
    this.toggleSubscribeAudio = this.toggleSubscribeAudio.bind(this);
    this.removeShareScreen = this.removeShareScreen.bind(this);
    this.shareScreen = this.shareScreen.bind(this);
    this.handleChangeMicrophone = this.handleChangeMicrophone.bind(this);
    this.handleChangeCamera = this.handleChangeCamera.bind(this);
    this.shareScreenSubscriber = this.shareScreenSubscriber.bind(this);
    this.toggleVisibleChat = this.toggleVisibleChat.bind(this);
    this.setMessages = this.setMessages.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onbeforeunload);
    !this.webRTCSupportTest() && createNotification('error', 'Вы используете браузер в котором не гарантируется стабильная работа видеоконференции', null, 8000)
  }
  
  componentDidUpdate () {
    // console.log(this.state.session)
    // console.log(this.state.session2)
    // console.log(this.state.publisher)
    // console.log(this.state.publisher2)
    // console.log(this.state.subscribers)
  }
  
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onbeforeunload);
  }
  
  getTopic() {
    const subsTopic = this.state.subscribers
    .map(it => JSON.parse(it.stream.connection.data).topic)
    .find(it => it)
    // const publTopic = JSON.parse(this.state.publisher.stream.connection.data).topic
    return subsTopic/*  || publTopic */
  }
  
  
  setMessages(message) {
    this.setState((prevState, props) => ({
      messages: [...prevState.messages, message],
    }));
  }

  toggleVisibleChat() {
    this.setState({ visibleChat: !this.state.visibleChat });
  }

  shareScreenSubscriber() {
    return this.state.subscribers.find(
      (e) =>
        JSON.parse(e.stream.connection.data).clientName === SHARE_SCREEN_NAME
    );
  }

  togglePublishAudio() {
    this.state.publisher.publishAudio(!this.state.publisher.stream.audioActive);
    this.setState({ render: null });
  }

  togglePublishVideo() {
    this.state.publisher.publishVideo(!this.state.publisher.stream.videoActive);
    this.setState({ render: null });
  }

  toggleSubscribeAudio(sub) {
    sub.subscribeToAudio(!sub.stream.audioActive);
    this.setState({ render: null });
  }

  removeShareScreen() {
    const mySession2 = this.state.session2;

    if (mySession2) {
      mySession2.disconnect();
    }

    this.OV2 = null;
    this.setState({ publisher2: undefined });
  }

  shareScreen() {
    if (this.shareScreenSubscriber()) return;

    this.OV2 = new OpenVidu();
    this.setState(
      {
        session2: this.OV2.initSession(),
      },
      () => {
        var mySession2 = this.state.session2;
        this.getToken().then((token) => {
          mySession2
            .connect(token, {
              clientName: SHARE_SCREEN_NAME,
              clientOrganization: this.state.myOrganization,
              clientPosition: this.state.myPosition,
            })
            .then(() => {
              let publisher2 = this.OV2.initPublisher(undefined, {
                publishAudio: this.state.isLeader ? true : false,
                publishVideo: this.state.isLeader ? true : false,
                resolution: "640x480",
                frameRate: 30,
                insertMode: "APPEND",
                mirror: false,
                audioSource: false,
                videoSource: this.state.isLeader ? 'screen' : null
              });
              mySession2.publish(publisher2);
              this.setState({
                publisher2: publisher2,
              });
            })
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
      }
    );
  }

  onbeforeunload(event) {
    this.leaveSession();
  }

  handleChangeSessionId(e) {
    this.setState({
      mySessionId: e.target.value,
    });
  }

  handleChangeUsername(e) {
    this.setState({
      myUsername: e.target.value,
    });
  }

  handleChangeMicrophone(microphoneId) {
    this.setState({ microphoneId });
  }

  handleChangeCamera(cameraId) {
    this.setState({ cameraId });
  }

  handleChangeOrganization(e) {
    this.setState({
      myOrganization: e.target.value,
    });
  }
  
  handleChangeTopic(e) {
    this.setState({
      topic: e.target.value,
    });
  }

  handleChangePosition(e) {
    this.setState({
      myPosition: e.target.value,
    });
  }

  handleMainVideoStream(stream) {
    if (this.state.mainStreamManager !== stream) {
      this.setState({
        mainStreamManager: stream,
      });
    }
  }

  handleFoolScreenVideoStream(stream) {
    if (this.state.foolScreenStreamManager === stream) {
      this.setState({
        foolScreenStreamManager: undefined,
      });
    } else {
      this.setState({
        foolScreenStreamManager: stream,
      });
    }
  }

  handleFoolScreenVideoStreamNew(stream) {
    if (this.props.foolScreenNew === stream) {
      this.props.setFoolScreenNew(undefined)
    } else {
      this.props.setFoolScreenNew(stream)
    }
  }

  webRTCSupportTest () {
    const isWebRTCSupported = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        window.RTCPeerConnection

    if (window.navigator.userAgent.indexOf("Edge") > -1) return false
    if (isWebRTCSupported) { return true } else { return false }
  }

  deleteSubscriber(streamManager) {
    let subscribers = this.state.subscribers;
    let index = subscribers.indexOf(streamManager, 0);

    if (index > -1) {
      subscribers.splice(index, 1);
      if (
        this.state.mainStreamManager &&
        streamManager.stream.streamId ===
          this.state.mainStreamManager.stream.streamId
      ) {
        this.setState({
          mainStreamManager: subscribers.filter(
            (e) =>
              JSON.parse(e.stream.connection.data).clientName !==
              SHARE_SCREEN_NAME
          )[0],
          subscribers: subscribers,
        });
      } else {
        this.setState({
          subscribers: subscribers,
        });
      }
    }
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        this.props.setAppSession(true);
        var mySession = this.state.session;

        mySession.on("streamPropertyChanged", (event) => {
          this.setState({ render: null });
        });

        mySession.on('publisherStartSpeaking', event => {
          this.setState({ speakingUserId: event.connection.connectionId })
        })

        mySession.on("streamCreated", (event) => {
          var subscriber = mySession.subscribe(event.stream, undefined);
          var subscribers = this.state.subscribers;
          subscribers.push(subscriber);

          this.setState({
            mainStreamManager: subscribers.filter(
              (e) =>
                JSON.parse(e.stream.connection.data).clientName !==
                SHARE_SCREEN_NAME
            )[0],
            subscribers: subscribers,
          });
        });

        mySession.on("streamDestroyed", (event) => {
          this.deleteSubscriber(event.stream.streamManager);
        });

        this.getToken().then((token) => {
          mySession
            .connect(token, {
              clientName: this.state.myUsername,
              clientOrganization: this.state.myOrganization,
              clientPosition: this.state.myPosition,
              topic: this.state.topic
            })
            .then(() => {
              let publisher = this.OV.initPublisher(undefined, {
                publishAudio: this.state.isLeader ? true : false,
                publishVideo: this.state.isLeader ? true : false,
                resolution: "640x480",
                frameRate: 30,
                insertMode: "APPEND",
                mirror: false,
                audioSource: true,
                videoSource: this.state.isLeader ? this.state.cameraId : null
              });

              mySession.publish(publisher);

              this.setState({
                publisher: publisher,
              });
            })
            .then(
              (_) =>
                !this.props.location.pathname.substr(1) &&
                this.props.history.push(`/${this.state.mySessionId}`)
            )
            .catch((error) => {
              console.log(
                "There was an error connecting to the session:",
                error.code,
                error.message
              );
            });
        });
      }
    );
  }
  
  leaveSession() {
    const mySession = this.state.session;
    const mySession2 = this.state.session2;

    if (mySession) {
      mySession.disconnect();
    }

    if (mySession2) {
      mySession2.disconnect();
    }
    this.OV = null;
    this.setState({
      session: undefined,
      session2: undefined,
      subscribers: [],
      mySessionId: `SessionA-${Date.now()}`,
      myUsername: "",
      myOrganization: "",
      myPosition: "",
      mainStreamManager: undefined,
      publisher: undefined,
    });

    this.props.history.push("/");
    this.props.setAppSession(false);
  }
  

  getToken() {
    return this.createSession(this.state.mySessionId).then((sessionId) =>
      this.createToken(sessionId)
    );
  }

  createSession(sessionId) {
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({ customSessionId: sessionId });
      axios
        .post(process.env.REACT_APP_URL + "/openvidu/api/sessions", data, {
          headers: {
            Authorization:
              "Basic " + btoa("OPENVIDUAPP:" + process.env.REACT_APP_SECRET),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          resolve(response.data.id);
        })
        .catch((response) => {
          var error = Object.assign({}, response);
          if (error.response.status === 409) {
            resolve(sessionId);
          } else {
            console.log(error);
            console.warn(
              "No connection to OpenVidu Server. This may be a certificate error at " +
                process.env.REACT_APP_URL
            );
            if (
              window.confirm(
                'No connection to OpenVidu Server. This may be a certificate error at "' +
                  process.env.REACT_APP_URL +
                  '"\n\nClick OK to navigate and accept it. ' +
                  'If no certificate warning is shown, then check that your OpenVidu Server is up and running at "' +
                  process.env.REACT_APP_URL +
                  '"'
              )
            ) {
              window.location.assign(
                process.env.REACT_APP_URL + "/accept-certificate"
              );
            }
          }
        });
    });
  }

  createToken(sessionId) {
    return new Promise((resolve, reject) => {
      var data = {};
      axios
        .post(
          process.env.REACT_APP_URL +
            "/openvidu/api/sessions/" +
            sessionId +
            "/connection",
          data,
          {
            headers: {
              Authorization:
                "Basic " + btoa("OPENVIDUAPP:" + process.env.REACT_APP_SECRET),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          resolve(response.data.token);
        })
        .catch((error) => reject(error));
    });
  }

  render() {
    const { view, changeView } = this.props;
    return (
      <div /* className="container" */>
        {this.state.foolScreenStreamManager && (
          <FoolScreen
            streamManager={this.state.foolScreenStreamManager}
            handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
            isPublisher={
              this.state.foolScreenStreamManager.stream.streamId ===
              this.state.publisher.stream.streamId
            }
          />
        )}
        {this.props.foolScreenNew && (
          <NewFoolScreen
            streamManager={this.props.foolScreenNew}
            handleFoolScreenVideoStream={this.handleFoolScreenVideoStreamNew}
            subscribers={[this.state.publisher, this.state.mainStreamManager]}
            isPublisher={
              this.props.foolScreenNew.stream.streamId ===
              this.state.publisher.stream.streamId
            }
          />
        )}
        {this.state.session === undefined ? (
          <Form
            mySessionPath={this.state.mySessionPath}
            myUsername={this.state.myUsername}
            myOrganization={this.state.myOrganization}
            myPosition={this.state.myPosition}
            topic={this.state.topic}
            joinSession={this.joinSession}
            changeSessionId={this.handleChangeSessionId}
            changeUsername={this.handleChangeUsername}
            changeTopic={this.handleChangeTopic}
            changeOrganization={this.handleChangeOrganization}
            changePosition={this.handleChangePosition}
            changeMicrophone={this.handleChangeMicrophone}
            changeCamera={this.handleChangeCamera}
            location={this.props.location}
          />
        ) : null}
        {this.state.publisher !== undefined && (
          <Route exact path={`/${this.state.mySessionId}`}>
            <Controllers
              positionDown={this.state.foolScreenStreamManager||this.state.foolScreenNew}
              shareScreenSubscriber={this.shareScreenSubscriber}
              state={this.state}
              togglePublishAudio={this.togglePublishAudio}
              togglePublishVideo={this.togglePublishVideo}
              removeShareScreen={this.removeShareScreen}
              shareScreen={this.shareScreen}
              SHARE_SCREEN_NAME={SHARE_SCREEN_NAME}
              leaveSession={this.leaveSession}
              handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
              toggleVisibleChat={this.toggleVisibleChat}
            />
            <Chat
              session={this.state.session}
              messages={this.state.messages}
              setMessages={this.setMessages}
              visibleChat={this.state.visibleChat}
            />
            {view === 1 && (
              <View1
                state={this.state}
                shareScreenSubscriber={this.shareScreenSubscriber}
                handleMainVideoStream={this.handleMainVideoStream}
                handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
                handleFoolScreenVideoStreamNew={this.handleFoolScreenVideoStreamNew}
                handleChangeCname={this.handleChangeUsername}
                handleChangeOrganization={this.handleChangeOrganization}
                handleChangePosition={this.handleChangePosition}
                SHARE_SCREEN_NAME={SHARE_SCREEN_NAME}
                toggleSubscribeAudio={this.toggleSubscribeAudio}
                screenWidth={this.props.screenWidth}
              />
            )}

            {view === 2 && (
              <View2
                subscribers={this.state.subscribers}
                publisher={this.state.publisher}
                handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
                toggleSubscribeAudio={this.toggleSubscribeAudio}
              />
            )}

            {view === 3 && (
              <View3
                subscribers={this.state.subscribers}
                publisher={this.state.publisher}
                handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
                toggleSubscribeAudio={this.toggleSubscribeAudio}
              />
            )}

            {view === 4 && (
              <View4
                subscribers={this.state.subscribers}
                publisher={this.state.publisher}
                handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
                toggleSubscribeAudio={this.toggleSubscribeAudio}
              />
            )}

            {view === 5 && (
              <View5
                subscribers={this.state.subscribers}
                publisher={this.state.publisher}
                handleFoolScreenVideoStream={this.handleFoolScreenVideoStream}
                toggleSubscribeAudio={this.toggleSubscribeAudio}
              />
            )}
            
            <UsersCount usersCount={this.state.publisher.session.remoteConnections.size ? 
              this.state.publisher.session.remoteConnections.size + 1: 
              this.state.publisher.session.remoteConnections.size + 1} 
              list={this.state.session?.connection.localOptions}/>
          </Route>
        )}
      </div>
    );
  }

}

export default withRouter(VideoChatPage);
